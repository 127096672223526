import React, { ElementType } from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import { css } from '@emotion/react';

interface Props {
  options: string[];
  open?: boolean;
  value: string;
  variant: 'standard' | 'outlined';
  minWidth?: string;
  vertical: number;
  horizontal: number;
  IconComponent?: ElementType;
  onOpen?: () => void;
  onClose?: () => void;
  onChange: (e: SelectChangeEvent) => void;
}

const StandardSelectStyle = css`
  font-size: 14px;
  height: 19px;
  color: #ffffff;
  div {
    padding: 0 !important;
  }
  :before,
  :after,
  :hover:not(.Mui-disabled):before {
    border: none;
  }
`;

const OutlinedSelectStyle = css`
  height: 36px;
`;

export default function EZSelect({
  options,
  value,
  variant,
  minWidth,
  vertical,
  horizontal,
  IconComponent,
  onChange,
  ...rest
}: Props): React.ReactElement {
  return (
    <FormControl
      variant={variant}
      size="small"
      css={css`
        margin-bottom: 12px;
        min-width: ${minWidth};
      `}
    >
      <Select
        value={value}
        onChange={onChange}
        IconComponent={IconComponent}
        {...rest}
        css={css`
          ${variant === 'standard' ? StandardSelectStyle : OutlinedSelectStyle};
        `}
        MenuProps={{
          anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
          transformOrigin: {
            vertical,
            horizontal,
          },
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={option}
            value={option}
            css={css`
              min-width: 145px;
            `}
          >
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

EZSelect.defaultProps = {
  minWidth: '',
  IconComponent: undefined,
};
