import React from 'react';
import Typography, { TypographyProps } from '@mui/material/Typography';
import { css } from '@emotion/react';

interface Props extends TypographyProps {
  variant: 'caption';
  align?: 'center' | 'left' | 'right';
  children: string;
  color: 'black' | 'lightgray' | 'gray' | 'white' | 'red';
  shadow?: string;
  fontSize?: string;
  fontWeight?: string;
}

const colors = {
  black: '#222222',
  lightgray: '#F2F2F2',
  gray: '#999999',
  white: '#FFFFFF',
  red: '#F50057',
};

export default function EZTypography({
  variant,
  align,
  children,
  color,
  fontSize,
  fontWeight,
  shadow,
  ...rest
}: Props): React.ReactElement {
  return (
    <Typography
      variant={variant}
      align={align}
      whiteSpace="pre-wrap"
      css={css`
        color: ${colors[color]};
        text-shadow: ${shadow};
        font-size: ${fontSize};
        font-weight: ${fontWeight};
        letter-spacing: normal;
      `}
      {...rest}
    >
      {children}
    </Typography>
  );
}

EZTypography.defaultProps = {
  variant: 'caption',
  align: 'center',
  children: '',
  color: 'black',
  shadow: '',
  fontSize: '14px',
  fontWeight: '600',
};
