import pbkdf2 from 'pbkdf2';

export default function createChallengeAnswer(salt: string, plainPassword: string) {
  return new Promise((resolve, reject) => {
    pbkdf2.pbkdf2(plainPassword, salt, 999, 64, 'sha256', (error, key: Buffer) => {
      if (error) reject(error);
      resolve(key.toString('base64'));
    });
  });
}
