import {
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Stack,
  FormHelperText,
  Box,
} from '@mui/material';
import React, { useState } from 'react';
import { css } from '@emotion/react';
import { t } from '@lingui/macro';
import { i18n } from '@lingui/core';
import EZSpinner from '../common/EZSpinner';
import EZButton from '../common/EZButton';
import EZTypography from '../common/EZTypography';
import { Loading } from '../common/const';
import { VisibilityOffIcon, VisibilityONIcon, SmallWarningIcon } from '../assets/index';
import { IPasswordPolicyResponse } from '../types';

interface Props {
  title: string;
  validPassword?: boolean;
  passwordPolicy?: IPasswordPolicyResponse;
  onClickViewButton?: (
    event: React.MouseEvent<HTMLElement> | React.FormEvent<HTMLFormElement>,
    password: string
  ) => void;
  password: string;
  setPassword: React.Dispatch<React.SetStateAction<string>>;
  buttonDisable: boolean;
}

export default function LoginBox({
  title,
  validPassword,
  passwordPolicy,
  onClickViewButton,
  password,
  setPassword,
  buttonDisable,
}: Props): React.ReactElement {
  const [showPassword, setShowPassword] = useState(false);

  const ErrorMessage = () => {
    const helperText = validPassword === false ? i18n._(t`Invalid Password`) : '';
    return helperText;
  };

  const handlePasswordChange = (event: { target: { value: React.SetStateAction<string> } }) => {
    setPassword(event.target.value);
  };

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const handleViewButtonClick = async (
    event: React.MouseEvent<HTMLElement> | React.FormEvent<HTMLFormElement>
  ) => {
    if (onClickViewButton !== undefined) onClickViewButton(event, password);
  };

  return (
    <Stack
      css={css`
        width: 700px;
        align-items: center;
      `}
    >
      <Stack
        css={css`
          position: relative;
          width: 700px;
          max-height: 446px;
          padding: 90px 112px;
          margin-bottom: 24px;
          background-color: rgba(0, 0, 0, 0.6);
          border-radius: 10px;
          text-align: center;
          align-items: center;
        `}
      >
        <div
          css={css`
            min-width: 280px;
            max-width: 476px;
          `}
        >
          {title ? (
            <EZTypography
              color="white"
              align="left"
              fontSize="20px"
              css={css`
                max-width: 476px;
                text-overflow: ellipsis;
                overflow: hidden;
                word-break: break-word;
                line-height: 1.5;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
              `}
            >
              {title}
            </EZTypography>
          ) : (
            <EZSpinner loading={Loading.previewInfo} />
          )}
          <Box component="form" onSubmit={handleViewButtonClick}>
            <FormControl
              variant="standard"
              error={validPassword === false}
              css={css`
                width: 100%;
                height: 63px;
                margin-top: 20px;
                margin-bottom: 60px;
                :hover {
                  label {
                    color: #a3a3a3s;
                  }
                }
              `}
            >
              <InputLabel
                css={css`
                  color: #a3a3a3;
                `}
              >
                Password
              </InputLabel>
              <Input
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={handlePasswordChange}
                fullWidth
                inputProps={{
                  maxLength: passwordPolicy?.maximumLength,
                  minLength: passwordPolicy?.minimumLength,
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                    >
                      {showPassword ? (
                        <VisibilityONIcon fill="#CECECE" />
                      ) : (
                        <VisibilityOffIcon fill="#CECECE" />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                css={css`
                  height: 44px;
                  color: #ffffff;
                  padding-left: 14px;
                  :before {
                    border-bottom: 2px solid #ffffff;
                    opacity: 0.6;
                  }
                  :hover:not(.Mui-disabled):before {
                    border-bottom: 2px solid #ffffff;
                    opacity: 1;
                  }
                `}
              />
              <FormHelperText>{ErrorMessage()}</FormHelperText>
            </FormControl>
            <EZButton
              type="submit"
              width="280px"
              height="36px"
              bgColor="primary"
              text="VIEW"
              disabled={buttonDisable}
              onClick={handleViewButtonClick}
            />
          </Box>
        </div>
      </Stack>
      <Stack
        css={css`
          width: 644px;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          margin-bottom: 17px;
        `}
      >
        <SmallWarningIcon
          width="28px"
          height="26px"
          css={css`
            margin-right: 16px;
          `}
        />
        <EZTypography
          color="white"
          fontSize="12px"
          fontWeight="500"
          shadow="0px 1px 6px #00000033"
          width="600px"
          align="left"
        >
          {i18n._(
            t`The next page may show the personal data of a third person which should be strictly protected. 
   If you are not the designated recipient, or not allowed, do not have the right to access this under the relevant laws and regulations, please close this page.`
          )}
        </EZTypography>
      </Stack>
    </Stack>
  );
}
