import React, { useEffect, useState } from 'react';
import { css, Stack } from '@mui/material';
import { SharedIcon } from '../assets/index';
import { getCountryFlag } from '../common/functions';
import EZTypography from '../common/EZTypography';
import EZSelect from '../common/EZSelect';
import { languageOptions, Languages } from '../common/const';

export default function SNB(): React.ReactElement {
  const [language, setLanguage] = useState<string>(
    localStorage.getItem('eziCloud.language') || Languages.en
  );
  const [selectOpen, setSelectOpen] = useState(false);

  const handleSelectClose = () => {
    setSelectOpen(false);
  };

  const handleSelectOpen = () => {
    setSelectOpen(true);
  };

  const handleLanguageChange = (e: { target: { value: string } }) => {
    setLanguage(e.target.value);
  };

  useEffect(() => {
    localStorage.setItem('eziCloud.language', language);
  }, [language]);

  return (
    <Stack
      css={css`
        width: 70px;
      `}
    >
      <Stack
        css={css`
          justify-content: center;
          align-items: center;
          background: #2b918b;
          height: 132px;
        `}
      >
        <SharedIcon
          css={css`
            margin-bottom: 5px;
          `}
        />
        <EZTypography color="white">Shared</EZTypography>
      </Stack>
      <Stack
        css={css`
          justify-content: end;
          align-items: center;
          background: #333945;
          flex: 1;
          padding: 32px 0;
        `}
      >
        {getCountryFlag(language, handleSelectOpen)}
        <EZSelect
          options={languageOptions}
          open={selectOpen}
          onOpen={handleSelectOpen}
          onClose={handleSelectClose}
          value={language}
          variant="standard"
          vertical={120}
          horizontal={-20}
          IconComponent={() => null}
          onChange={handleLanguageChange}
        />
      </Stack>
    </Stack>
  );
}
