/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { css, Stack } from '@mui/material';
import { t } from '@lingui/macro';
import { i18n } from '@lingui/core';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { useUpdateAuthState } from '../common/auth/authContext';
import Header from './Header';
import EZSpinner from '../common/EZSpinner';
import SNB from './SNB';
import EZImageList from '../common/EZImageList';
import EZImageListItem from '../common/EZImageListItem';
import EziCloudApis from '../apis';
import { ICaseDetailInfoResponse } from '../types';
import { Loading, ErrorCodes } from '../common/const';
import ErrorDialog, { IErrorDialogProps } from './ErrorDialog';
import AuthData from '../common/auth/authData';

interface IUrl {
  loading: boolean;
  src: string;
}

export default function SharedImages(): React.ReactElement {
  const { uuid } = useParams();
  const navigate = useNavigate();
  const apis = useRef<EziCloudApis>(new EziCloudApis());
  const location = useLocation();

  const [loading, setLoading] = useState<Loading.image | Loading.download | ''>('');
  const [data, setData] = useState<ICaseDetailInfoResponse>();
  const [count, setCount] = useState<number>(0);
  const [maxCount, setMaxCount] = useState<number>(0);
  const [urls, setUrls] = useState<IUrl[]>([]);
  const [errorDialogState, setErrorDialogState] = useState<
    Pick<IErrorDialogProps, 'open' | 'message'>
  >({
    open: false,
    message: '',
  });

  const handleErrorDialog = useCallback((isOpen = false, message = '') => {
    setErrorDialogState({
      open: isOpen,
      message,
    });
  }, []);

  const updateAuthState = useUpdateAuthState();

  useEffect(() => {
    if (location.state) {
      if (location.state.downloadPolicy) {
        setMaxCount(location.state.downloadPolicy.maximumCount);
      }
    }
  }, [location.state]);

  const caseBriefInfoApi = async () => {
    try {
      if (uuid) {
        const res = await apis.current.getCaseBriefInfo(uuid);

        setCount(Number(res.downloadCount) + 1);
        return Number(res.downloadCount);
      }
      return -1;
    } catch (e) {
      console.log(e);
      return -1;
    }
  };

  const caseDetailInfoApi = async () => {
    try {
      if (uuid) {
        const res = await apis.current.postCaseDetailInfo(uuid);
        setData(res);
        return Number(res.downloadCount);
      }
      return -1;
    } catch (e) {
      console.log(e);
      return -1;
    }
  };

  const checkDownloadCountExceeded = useCallback(async (): Promise<boolean> => {
    const res = await caseBriefInfoApi();
    if (res >= 0 && res < maxCount) return false;

    handleErrorDialog(
      true,
      i18n._(t`The maximum number of downloads has been exceeded. (${maxCount} times per share)`)
    );
    return true;
  }, [caseBriefInfoApi, handleErrorDialog, i18n, maxCount]);

  const onClickDownloadButton = useCallback(async (): Promise<void> => {
    if (data === undefined) return;
    try {
      const res = await apis.current.postDownloadUrlR2('Case', data.caseFileKey);
      const response = await apis.current.downloadFile(res.url);

      const a = document.createElement('a');
      a.href = response;
      a.download = dayjs(new Date()).format('YYYYMMDD.zip');
      a.click();
      a.remove();

      caseBriefInfoApi();
    } catch (error) {
      if (
        error &&
        (error as any).response &&
        (error as any).response.data &&
        (error as any).response.data.errorCode === ErrorCodes.Expired
      ) {
        navigate(`/linksharing/guest/${uuid}`);
      } else {
        handleErrorDialog(true, i18n._(t`Failed to download shared data. Please try again later.`));
      }
    }
  }, [data, caseBriefInfoApi, navigate, handleErrorDialog, i18n]);

  const handleDoubleClick = (e: { currentTarget: { currentSrc: string } }) => {
    const image = new Image();
    image.src = e.currentTarget.currentSrc;

    const w = window.open('');
    w?.document.write(image.outerHTML);
  };

  const caseDownloadApi = async (signedURL: string) => {
    try {
      setUrls((prev) => [...prev, { loading: true, src: signedURL }]);
      const res = await apis.current.downloadFile(signedURL);
      setUrls((prev) =>
        prev.map((url) => (url.src === signedURL ? { loading: false, src: res } : url))
      );
    } catch (e) {
      console.log(e);
    }
  };

  const postDownloadUrlApi = async (fileKey: string) => {
    try {
      const res = await apis.current.postDownloadUrlR2('Preview', fileKey);
      caseDownloadApi(res.url);
    } catch (e) {
      console.log(e);
    }
  };

  const getImageList = () =>
    urls.map((value) => {
      if (value.src === '')
        return (
          <Stack
            css={css`
              background: #000000;
              border: 1px #242424 solid;
              flex: 1;
              justify-content: center;
              text-align: center;
              color: white;
              height: 460px;
              :hover {
                border: 4px #2b918b solid;
              }
            `}
          >
            {i18n._(t`Preview function not supported.`)}
          </Stack>
        );
      if (value.loading)
        return (
          <div
            key={value.src}
            css={css`
              display: flex;
              justify-content: center;
              height: 460px;
            `}
          >
            <EZSpinner loading={Loading.image} />
          </div>
        );

      return (
        <EZImageListItem
          key={value.src}
          src={value.src}
          alt={value.src}
          onDoubleClick={handleDoubleClick}
        />
      );
    });

  const setHeader = useCallback(async () => {
    const { valid, credentials } = await AuthData.getSessionByCurrentUser();
    if (valid) {
      apis.current.setDefaultToken(`Bearer ${credentials.accessToken}`);
    }
  }, []);

  const getInfo = useCallback(async () => {
    const { valid } = await AuthData.getSessionByCurrentUser();
    if (valid) {
      await caseDetailInfoApi();
    }
  }, []);

  useEffect(() => {
    setHeader();
    getInfo();
    const onHandleTokenExpired = (): void => {
      updateAuthState();
      navigate(`/linksharing/guest/${uuid}`);
    };

    apis.current.addResponseInterceptors(401, onHandleTokenExpired);
  }, []);

  useEffect(() => {
    if (data) {
      data.previewFileKeys.forEach((fileKey) => {
        if (fileKey !== '') {
          postDownloadUrlApi(fileKey);
        } else {
          setUrls((prev) => [...prev, { loading: false, src: '' }]);
        }
      });
    }
  }, [data]);

  return (
    <Stack
      css={css`
        height: 100vh;
      `}
    >
      <Stack
        css={css`
          flex-direction: row;
          height: 100%;
        `}
      >
        <SNB />
        <Stack
          css={css`
            flex: 1;
            position: relative;
          `}
        >
          <EZSpinner loading={loading} />
          {data && uuid && (
            <Header
              data={data}
              count={count}
              maxCount={maxCount}
              onClickDownloadButton={onClickDownloadButton}
              checkDownloadCountExceeded={checkDownloadCountExceeded}
              setLoading={setLoading}
            />
          )}
          <EZImageList gap={1} cols={3}>
            {getImageList()}
          </EZImageList>
        </Stack>
      </Stack>
      <ErrorDialog
        open={errorDialogState.open}
        onClose={() => handleErrorDialog()}
        message={errorDialogState.message}
      />
    </Stack>
  );
}
