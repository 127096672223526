import React from 'react';
import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import { en, es, fr } from 'make-plural/plurals';
import { ThemeProvider } from '@mui/material/styles';
import { Global } from '@emotion/react';
import { useRoutes, BrowserRouter } from 'react-router-dom';
import AuthLayout from './layout/AuthLayout';
import PublicLayout from './layout/PublicLayout';

import theme from './common/defines/theme';
import { Languages } from './common/const';
import catalogEnUS from './locales/en_US/messages';
import catalogEsES from './locales/es_ES/messages';
import catalogfrFR from './locales/fr_FR/messages';
import Login from './login';
import SharedImages from './sharedImages';
import Compliance from './compliance/imageShare/Compliance';
import Agreements from './agreements/Agreements';
import reset from './styles/global';
import AuthProvider from './common/auth/authContext';

const catalogs = {
  English: catalogEnUS.messages,
  en: catalogEnUS.messages,
  Spanish: catalogEsES.messages,
  es: catalogEsES.messages,
  French: catalogfrFR.messages,
  fr: catalogfrFR.messages,
};

const plurals = {
  English: { plurals: en },
  en: { plurals: en },
  Spanish: { plurals: es },
  es: { plurals: es },
  French: { plurals: fr },
  fr: { plurals: fr },
};

i18n.loadLocaleData(plurals);
i18n.load(catalogs as never);
i18n.activate(localStorage.getItem('eziCloud.language') || Languages.en);

function AppRoutes() {
  const publicRoutes = {
    path: '/linksharing',
    element: <PublicLayout />,
    children: [
      { path: 'guest/:uuid', element: <Login /> },
      { path: 'compliance', element: <Compliance /> },
      { path: 'agreements', element: <Agreements /> },
    ],
  };

  const authRoutes = {
    path: '/linksharing/guest/:uuid',
    element: <AuthLayout />,
    children: [
      {
        path: 'images',
        element: <SharedImages />,
      },
    ],
  };
  const routes = useRoutes([publicRoutes, authRoutes]);

  return routes;
}
function App() {
  return (
    <ThemeProvider theme={theme}>
      <Global styles={reset} />
      <I18nProvider i18n={i18n}>
        <AuthProvider>
          <BrowserRouter>
            <AppRoutes />
          </BrowserRouter>
        </AuthProvider>
      </I18nProvider>
    </ThemeProvider>
  );
}
export default App;
