import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { css } from '@emotion/react';
import { t } from '@lingui/macro';
import { i18n } from '@lingui/core';
import EZTypography from './EZTypography';
import { Loading } from './const';

interface Props {
  loading: Loading | '';
}

export default function EZSpinner({ loading }: Props): React.ReactElement {
  const loadingType = {
    [Loading.login]: { text: undefined, position: 'absolute', size: '33px', invisible: false },
    [Loading.image]: { text: undefined, position: 'relative', size: '50px', invisible: true },
    [Loading.download]: {
      text: i18n._(t`Downloading images…`),
      position: 'absolute',
      size: '50px',
      invisible: false,
    },
    [Loading.previewInfo]: {
      text: undefined,
      position: 'relative',
      size: '33px',
      invisible: true,
    },
    '': { text: undefined, position: '', size: '0', invisible: true },
  };

  return (
    <Backdrop
      invisible={loadingType[loading].invisible}
      css={css`
        position: absolute;
        position: ${loadingType[loading].position};
        display: flex;
        flex-direction: column;
        z-index: 1000;
      `}
      open={Boolean(loading)}
    >
      <CircularProgress
        css={css`
          color: #20ecb4;
          margin-bottom: ${loadingType[loading].text ? '20px' : '0px'};
        `}
        size={loadingType[loading].size}
        thickness={3}
      />
      {loadingType[loading].text && (
        <EZTypography fontSize="13px" color="white">
          {loadingType[loading].text}
        </EZTypography>
      )}
    </Backdrop>
  );
}
