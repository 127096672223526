import { CognitoUser, CognitoUserPool, CognitoUserSession } from 'amazon-cognito-identity-js';
import CognitoStorage from './cognitoStorage';

interface ICognitoSession {
  valid: boolean;
  credentials: {
    accessToken: string;
    // TODO: Add when necessary.(idToken, refreshToken )
  };
}

class AuthData {
  private static userPoolInstance: CognitoUserPool;

  private static cognitoStorage: CognitoStorage;

  static getUserPool(): CognitoUserPool {
    if (!AuthData.userPoolInstance) {
      this.createUserPool();
    }
    return AuthData.userPoolInstance;
  }

  static createUser(uuid: string): CognitoUser {
    const userPool = AuthData.getUserPool();

    return new CognitoUser({
      Username: uuid,
      Pool: userPool,
      Storage: AuthData.cognitoStorage,
    });
  }

  static getSessionByCurrentUser(): Promise<ICognitoSession> {
    return new Promise((resolve, reject) => {
      if (AuthData.userPoolInstance) {
        const user = AuthData.userPoolInstance.getCurrentUser();
        if (user === null) reject(new Error('Current user is null'));
        user?.getSession((err: Error | null, result: CognitoUserSession | null) => {
          if (err || !result) {
            reject(new Error(`Failure getting cognito session: ${err}`));
          }
          if (result) {
            const session = {
              credentials: {
                accessToken: result.getAccessToken().getJwtToken(),
                // TODO: Add when necessary.(idToken, refreshToken )
              },
              valid: result.isValid(),
            };
            resolve(session);
          }
        });
      }
    });
  }

  private static createUserPool(): void {
    AuthData.cognitoStorage = new CognitoStorage();
    const config = {
      UserPoolId: process.env.REACT_APP_USERPOOL_ID || '',
      ClientId: process.env.REACT_APP_USERPOOL_CLIENT_ID || '',
      Storage: AuthData.cognitoStorage,
    };

    AuthData.userPoolInstance = new CognitoUserPool(config);
  }
}

export default AuthData;
