import React, { useState } from 'react';
import { css } from '@emotion/react';

import { Box, Stack } from '@mui/material';

import { getCountryDialog } from '../../common/functions';
import { Languages, languageOptions } from '../../common/const';
import EZSelect from '../../common/EZSelect';
import EZTypography from '../../common/EZTypography';

export default function Compliance(): React.ReactElement {
  const [complianceLanguage, setComplianceLanguage] = useState<string>(Languages.en);

  const handleDialogSelect = (e: { target: { value: string } }) => {
    setComplianceLanguage(e.target.value);
  };

  return (
    <Stack
      css={css`
        width: 960px;
        height: 100vh;
        justify-content: center;
        margin: 0 auto;
      `}
    >
      <Stack
        css={css`
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 2px;
        `}
      >
        <EZTypography fontSize="20px" fontWeight="500">
          Legacy
        </EZTypography>
        <EZSelect
          options={languageOptions}
          value={complianceLanguage}
          variant="outlined"
          vertical={0}
          horizontal={240}
          minWidth="240px"
          onChange={handleDialogSelect}
        />
      </Stack>
      <Box
        css={css`
          height: 280px;
          border: 1px solid #dddddd;
          background-color: #f0f0f0;
          padding: 14px;
          max-height: 670px;
          overflow-y: auto;
        `}
      >
        <EZTypography>{getCountryDialog(complianceLanguage)}</EZTypography>
      </Box>
    </Stack>
  );
}
