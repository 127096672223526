/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef, useState, useMemo } from 'react';
import { css } from '@emotion/react';
import { t } from '@lingui/macro';
import { i18n } from '@lingui/core';
import { Backdrop, Stack } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { AuthenticationDetails, CognitoUserSession } from 'amazon-cognito-identity-js';
import { AESHelper } from '@ewoosoft/ezicloud-client';
import EZSpinner from '../common/EZSpinner';
import { IPreviewerInfoResponse, ErrorStage } from '../types';
import EZTypography from '../common/EZTypography';
import { EzShareBiIcon } from '../assets/index';
import backgroundImage from '../assets/images/greenX.png';
import { getCountryFlag } from '../common/functions';
import { Languages, languageOptions, Loading } from '../common/const';
import EZSelect from '../common/EZSelect';
import LoginBox from './LoginBox';
import ErrorBox from './ErrorBox';
import EziCloudApis from '../apis';
import createChallengeAnswer from './utilities/auth';
import { useUpdateAuthState } from '../common/auth/authContext';
import AuthData from '../common/auth/authData';

export default function Login(): React.ReactElement {
  const { uuid } = useParams();
  const navigate = useNavigate();
  const apis = useRef<EziCloudApis>(new EziCloudApis());

  const updateAuthState = useUpdateAuthState();

  const [loading, setLoading] = useState<Loading.login | ''>('');

  const [validPassword, setValidPassword] = useState<boolean>(true);
  const [password, setPassword] = useState<string>('');

  const [language, setLanguage] = useState<string>(
    localStorage.getItem('eziCloud.language') || Languages.en
  );
  const [selectOpen, setSelectOpen] = useState(false);

  const [previewInfo, setPreviewInfo] = useState<
    Omit<IPreviewerInfoResponse, 'errorMessage' | 'errorCode'> | undefined
  >(undefined);

  const [errorStage, setErrorStage] = useState<ErrorStage | null>(null);

  const loginBoxTitle: string = useMemo(() => previewInfo?.case.title || '', [previewInfo]);
  const buttonDisable: boolean = useMemo(
    () => !(loginBoxTitle && password),
    [loginBoxTitle, password]
  );

  const handleSelectClose = () => {
    setSelectOpen(false);
  };

  const handleSelectOpen = () => {
    setSelectOpen(true);
  };

  const handleLanguageChange = (e: { target: { value: string } }) => {
    setLanguage(e.target.value);
  };

  const onClickViewButton = async (
    event: React.MouseEvent<HTMLElement> | React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    event.preventDefault();
    setLoading(Loading.login);
    try {
      if (!uuid) throw new Error('Incorrect uuid');

      const user = AuthData.createUser(uuid);

      user.setAuthenticationFlowType('CUSTOM_AUTH');
      user.initiateAuth(new AuthenticationDetails({ Username: uuid }), {
        async onSuccess(session: CognitoUserSession) {
          if (session.isValid()) {
            setValidPassword(true);
            await updateAuthState();
            navigate('images', {
              state: {
                downloadPolicy: previewInfo?.policy.downloadPolicy,
              },
            });
          } else {
            setErrorStage(ErrorStage.Failed);
          }
          setLoading('');
        },
        onFailure(err: any) {
          setLoading('');
          if (err.code === 'NotAuthorizedException') setValidPassword(false);
          else setErrorStage(ErrorStage.Failed);
        },
        async customChallenge(challengeParameters: any) {
          const encryptedPassword = AESHelper.encrypt(password);
          const challengeData = await createChallengeAnswer(
            challengeParameters.code,
            encryptedPassword
          );
          user.sendCustomChallengeAnswer(challengeData, this);
        },
      });
    } catch (error) {
      setErrorStage(ErrorStage.Failed);
      setLoading('');
    }
  };

  const getPreviewerInfo = async () => {
    if (uuid) {
      try {
        const res = await apis.current.getPreviewerInfo(uuid);

        if (res.errorCode === 'Success') {
          const { case: caseInfo, policy } = res;
          setPreviewInfo({
            case: caseInfo,
            policy,
          });
          if (
            caseInfo.state === 'wait' ||
            caseInfo.state === 'downloadQuotaExceeded' ||
            caseInfo.state === 'failed' ||
            caseInfo.state === 'expired'
          )
            setErrorStage(ErrorStage.FailedCaseState);
        } else {
          setErrorStage(ErrorStage.Failed);
        }
      } catch (error) {
        setErrorStage(ErrorStage.Failed);
      }
    }
  };

  useEffect(() => {
    getPreviewerInfo();
  }, [uuid]);

  const getBoxInside = (): React.ReactNode => {
    switch (errorStage) {
      case ErrorStage.Failed:
      case ErrorStage.FailedCaseState:
        return (
          <ErrorBox
            errorStage={errorStage}
            caseState={previewInfo?.case.state}
            maxCount={previewInfo?.policy.downloadPolicy.maximumCount}
          />
        );
      default:
        return (
          <LoginBox
            title={loginBoxTitle}
            validPassword={validPassword}
            passwordPolicy={previewInfo?.policy.passwordPolicy}
            onClickViewButton={onClickViewButton}
            password={password}
            setPassword={setPassword}
            buttonDisable={buttonDisable}
          />
        );
    }
  };

  return (
    <Stack
      css={css`
        align-items: center;
        justify-content: center;
        height: 100vh;
      `}
    >
      {loading === Loading.login ? <EZSpinner loading={Loading.login} /> : null}
      <Backdrop
        open
        css={css`
          background-image: url(${backgroundImage});
          background-size: 110%;
          filter: blur(8px);
          z-index: -1000;
        `}
      />
      <div
        css={css`
          position: absolute;
          top: 24px;
          right: 44px;
          display: flex;
          flex-direction: column;
          width: 40px;
          align-items: center;
        `}
      >
        {getCountryFlag(language, handleSelectOpen)}
        <EZSelect
          options={languageOptions}
          open={selectOpen}
          onOpen={handleSelectOpen}
          onClose={handleSelectClose}
          value={language}
          variant="standard"
          vertical={-10}
          horizontal={140}
          IconComponent={() => null}
          onChange={handleLanguageChange}
        />
      </div>
      <Stack>
        <Stack
          css={css`
            margin-left: 34px;
            margin-bottom: 26px;
            flex-direction: row;
            align-items: end;
          `}
        >
          <EzShareBiIcon
            css={css`
              margin-right: 4px;
            `}
          />
          <EZTypography
            fontSize="26px"
            fontWeight="400"
            color="lightgray"
            css={css`
              line-height: 1;
            `}
          >
            {i18n._(t`Data sharing service`)}
          </EZTypography>
        </Stack>
        {getBoxInside()}
      </Stack>
    </Stack>
  );
}
