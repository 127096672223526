/* eslint-disable class-methods-use-this */
import { ICognitoStorage } from 'amazon-cognito-identity-js';

let dataMemory: { [key: string]: string } = {};

class CognitoStorage implements ICognitoStorage {
  public setItem = (key: string, value: string): string => {
    dataMemory[key] = value;
    return dataMemory[key];
  };

  public getItem = (key: string): string =>
    Object.prototype.hasOwnProperty.call(dataMemory, key) ? dataMemory[key] : '';

  public removeItem = (key: string): boolean => delete dataMemory[key];

  public clear = (): { [key: string]: string } => {
    dataMemory = {};
    return dataMemory;
  };
}

export default CognitoStorage;
