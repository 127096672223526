import React, { ForwardedRef, ReactNode } from 'react';
import { css } from '@emotion/react';
import { Slide, Dialog, DialogTitle, DialogContent, IconButton } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { CloseIcon } from '../assets/index';

interface Props {
  margin?: string;
  title: string;
  open: boolean;
  children: ReactNode;
  transition?: boolean;
  onClose: () => void;
}

const Transition = React.forwardRef(
  (
    props: TransitionProps & {
      children: React.ReactElement;
    },
    ref: ForwardedRef<JSX.Element>
  ) => <Slide direction="up" ref={ref} {...props} />
);

export default function EZDialog({
  margin,
  title,
  open,
  children,
  transition,
  onClose,
}: Props): React.ReactElement {
  return (
    <Dialog maxWidth="xl" open={open} TransitionComponent={transition ? Transition : undefined}>
      <DialogTitle
        css={css`
          margin-left: 25px;
          padding: 19px 0;
          height: 64px;
        `}
      >
        {title}
        <IconButton
          aria-label="close"
          onClick={onClose}
          css={css`
            padding: 0;
            position: absolute;
            right: 12px;
            top: 12px;
          `}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        dividers
        css={css`
          border: none;
          margin: ${margin};
          padding: 0;
        `}
      >
        {children}
      </DialogContent>
    </Dialog>
  );
}

EZDialog.defaultProps = {
  padding: '16px 25px',
  transition: false,
};
