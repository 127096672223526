import React, { ReactNode } from 'react';
import ImageList, { ImageListProps } from '@mui/material/ImageList';
import { css } from '@emotion/react';

interface Props extends ImageListProps {
  children: ReactNode[];
  gap: number;
  cols: number;
}

export default function EZImageList({ children, gap, cols }: Props): React.ReactElement {
  return (
    <ImageList
      css={css`
        margin: 0;
        flex: 1;
        overflow-y: overlay;
        background: #242424;
      `}
      gap={gap}
      cols={cols}
      rowHeight={460}
    >
      {children}
    </ImageList>
  );
}
