import { css } from '@emotion/react';

const DialogScrollStyle = css`
  &::-webkit-scrollbar {
    width: 20px;
  },
  &::-webkit-scrollbar-thumb{
    background-color: #CCD0D4;
    border-radius: 10px;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    background-clip: content-box;
  },
`;

const ImageListScrollStyle = css`
  &::-webkit-scrollbar {
    width: 22px;
  },
  &::-webkit-scrollbar-thumb{
    background-color: rgb(133, 133,133, 0.9);
    border-radius: 11px;
    border: 7px solid transparent;
    background-clip: content-box;
  },
`;

const commentScrollStyle = css`
  &::-webkit-scrollbar {
    width: 12px;
  },
  &::-webkit-scrollbar-thumb{
    background-color: #DDDDDD;
    border-radius: 2px;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    background-clip: content-box;
  },
`;

const reset = css`
  @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

  * {
    box-sizing: border-box;
    font-family: 'Segoe UI', Roboto !important;
  }
  ,
  .MuiBox-root {
    ${DialogScrollStyle}
  }
  ,
  .MuiImageList-root {
    ${ImageListScrollStyle}
  }
  ,
  .MuiInputBase-root textarea {
    ${commentScrollStyle}
  }
  ,
  input::-ms-reveal,
  input::-ms-clear {
    display: none;
  }
`;

export default reset;
