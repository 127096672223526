/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { AuthServerApis, ApiServerApis, FileServerApis } from '@ewoosoft/ezicloud-client';
import { IPostAgreementsParams } from '@ewoosoft/ezicloud-client/dist/common/defines';
import {
  Policy,
  IResponse,
  IBriefInfoResponse,
  ICaseDetailInfoResponse,
  ISignedUrlResponse,
  IPolicyResponse,
  IPreviewerInfoResponse,
} from '../types';

class EziCloudApis {
  private authApis: AuthServerApis;

  private resourceApis: ApiServerApis;

  private fileApis: FileServerApis;

  constructor() {
    this.authApis = new AuthServerApis('AuthServerApis', process.env.REACT_APP_EZICLOUD_AUTH_PATH);
    this.resourceApis = new ApiServerApis('ApiServerApis', process.env.REACT_APP_BASE_PATH);
    this.fileApis = new FileServerApis('FileServerApis');
  }

  public addResponseInterceptors = (handleStatus: number, onHandleError: () => void): void => {
    this.resourceApis.axiosInstance.interceptors.response.use(
      (res) => res,
      async (error) => {
        if (error.response.status === handleStatus) {
          onHandleError();
          return Promise.reject(error);
        }
        return Promise.reject(error);
      }
    );
  };

  public getPreviewerInfo = (uuid: string): Promise<IPreviewerInfoResponse> =>
    this.resourceApis.getPreviewerInfo(uuid);

  public postClientCredentialsToken = (scopes?: string): Promise<string> =>
    Promise.resolve(
      this.authApis
        .postClientCredentialsToken({
          clientId: process.env.REACT_APP_CLIENT_AGREEMENTS_ID!,
          clientKey: process.env.REACT_APP_CLIENT_AGREEMENTS_SECRET_KEY!,
          scope: scopes || process.env.REACT_APP_CLIENT_SCOPE!,
        })
        .then((res) => `${res.token_type} ${res.access_token}`)
    );

  public isDefaultToken = (): boolean =>
    this.resourceApis.axiosInstance.defaults.headers.common.Authorization !== undefined &&
    this.fileApis.axiosInstance.defaults.headers.common.Authorization !== undefined;

  public setDefaultToken = (token: string): void => {
    this.resourceApis.axiosInstance.defaults.headers.common.Authorization = token;
    this.fileApis.axiosInstance.defaults.headers.common.Authorization = token;
  };

  public getPolicy = (policy: Policy[], token?: string): Promise<IPolicyResponse> =>
    this.resourceApis.getPolicy(this.getHeaders(token), policy);

  public postAgreements = (agreements: IPostAgreementsParams, token?: string): Promise<IResponse> =>
    this.resourceApis.postAgreements(this.getHeaders(token), agreements);

  public getCaseBriefInfo = (uuid: string, token?: string): Promise<IBriefInfoResponse> =>
    this.resourceApis.getCaseBriefInfo(this.getHeaders(token), uuid);

  public postCasePasswordVerification = (
    uuid: string,
    password: string,
    token?: string
  ): Promise<IResponse> =>
    this.resourceApis.postCasePasswordVerification(this.getHeaders(token), { uuid, password });

  public postCaseDetailInfo = (uuid: string, token?: string): Promise<ICaseDetailInfoResponse> =>
    this.resourceApis.postCaseDetailInfo(this.getHeaders(token), { uuid });

  public postDownloadUrlR2 = (
    fileType: string,
    fileKey: string,
    token?: string
  ): Promise<ISignedUrlResponse> =>
    this.resourceApis.postDownloadUrlR2(this.getHeaders(token), {
      fileType,
      fileKey,
      expiresIn: 900000,
    });

  public downloadFile = async (url: string, token?: string): Promise<string> => {
    const response = await this.fileApis.downloadFile(this.getHeaders(token), url);
    const signedurl = URL.createObjectURL(response);
    return signedurl;
  };

  // eslint-disable-next-line class-methods-use-this
  private getHeaders = (token?: string): any => (token ? { Authorization: token } : {});
}

export default EziCloudApis;
