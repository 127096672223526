import React, { useEffect } from 'react';
import { css, Stack, TextField } from '@mui/material';
import EZTypography from './EZTypography';

export interface IEZTextFieldProps {
  id: string;
  label: string;
  defaultValue: string;
  onChangeValue: (id: string, value: string) => void;
  error?: boolean;
}

export default function EzTextField(props: IEZTextFieldProps): React.ReactElement {
  const { id, label, defaultValue, onChangeValue, error } = props;

  useEffect(() => {
    onChangeValue(id, defaultValue);
  }, [defaultValue]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChangeValue(id, e.target.value);
  };

  return (
    <Stack
      css={css`
        width: 100%;
        justify-content: space-between;
        align-items: start;
      `}
    >
      <EZTypography>{label}</EZTypography>
      <TextField
        css={css`
          width: 100%;
          margin: 8px 0;
          .MuiInputBase-root {
            height: 36px;
          }
        `}
        error={error}
        defaultValue={defaultValue}
        onChange={onChange}
      />
    </Stack>
  );
}
