import React, { MouseEventHandler } from 'react';
import ImageListItem from '@mui/material/ImageListItem';
import { css } from '@emotion/react';

interface Props {
  src: string;
  alt: string;
  onDoubleClick: MouseEventHandler<HTMLImageElement>;
}

export default function EZImageListItem({ src, alt, onDoubleClick }: Props): React.ReactElement {
  return (
    <ImageListItem
      css={css`
        background: #000000;
        border: 1px #242424 solid;
        :hover {
          border: 4px #2b918b solid;
          img {
            height: 452px;
          }
        }
      `}
    >
      <img
        src={src}
        alt={alt}
        loading="lazy"
        onDoubleClick={onDoubleClick}
        style={{ objectFit: 'contain', maxHeight: '458px' }}
      />
    </ImageListItem>
  );
}
