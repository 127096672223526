import React, { useMemo } from 'react';
import { css } from '@emotion/react';
import { Stack } from '@mui/material';
import { t } from '@lingui/macro';
import { i18n } from '@lingui/core';
import { WarningIcon } from '../assets/index';
import EZTypography from '../common/EZTypography';
import { ErrorStage, CaseStateType } from '../types';

interface Props {
  errorStage: ErrorStage;
  caseState?: CaseStateType;
  maxCount?: number;
}

export default function ErrorBox(props: Props): React.ReactElement {
  const { errorStage, caseState, maxCount } = props;

  const getErrorStatus = (status: string | undefined): string => {
    switch (status) {
      case 'wait':
        return i18n._(t`Uploading Data…`);
      case 'failed':
        return i18n._(t`Data Upload Failed`);
      case 'expired':
        return i18n._(t`Link Expired`);
      case 'interrupted':
      case 'downloadQuotaExceeded':
      default:
        return i18n._(t`Download Limit Reached`);
    }
  };

  const getErrorMessage = (_state: string | undefined, _maxCount: number | undefined): string => {
    switch (_state) {
      case 'wait':
        return i18n._(
          t`Data upload for this link is still in progress. Please check again after upload is complete.`
        );
      case 'failed':
        return i18n._(
          t`Cannot view the shared link. Please check the upload status of the shared data with the sharer.`
        );
      case 'expired':
        return i18n._(t`Shared link has expired. (7 days from shared date)`);
      case 'downloadQuotaExceeded':
        return i18n._(
          t`The maximum number of downloads has been exceeded. (${_maxCount} times per share)`
        );
      default:
        return i18n._(
          t`The maximum number of downloads has been exceeded. (${_maxCount} times per share)`
        );
    }
  };

  const { title, message } = useMemo(() => {
    const result = {
      title: i18n._(t`Error`),
      message: i18n._(t`An unknown error occurred. Please try again later.`),
    };

    switch (errorStage) {
      case ErrorStage.FailedCaseState:
        result.title = getErrorStatus(caseState);
        result.message = getErrorMessage(caseState, maxCount);
        break;
      default:
        break;
    }
    return result;
  }, [errorStage, caseState, maxCount, getErrorStatus, getErrorMessage, i18n]);

  return (
    <Stack
      css={css`
        padding: 88px 165px;
        width: 700px;
        height: 386px;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 36px;
        background-color: rgba(0, 0, 0, 0.6);
        border-radius: 10px;
      `}
    >
      <WarningIcon width="50" height="42" />
      <EZTypography fontSize="22px" color="white">
        {title}
      </EZTypography>
      <EZTypography fontSize="18px" color="white" fontWeight="400">
        {message}
      </EZTypography>
    </Stack>
  );
}
