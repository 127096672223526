import React, { useEffect } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { useValidSession } from '../common/auth/authContext';

export default function AuthLayout() {
  const navigate = useNavigate();
  const { uuid } = useParams();

  const validSession = useValidSession();

  useEffect(() => {
    if (!validSession) {
      navigate(`/linksharing/guest/${uuid}`);
    }
  }, []);

  return <Outlet />;
}
