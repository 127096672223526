import React from 'react';
import { css } from '@emotion/react';
import { FlagFR, FlagES, FlagUS } from '../../assets';
import { Languages } from '../const';
import { imageShareEn, imageShareEs, imageShareFr } from '../../compliance';
import { agreementEn, agreementEs, agreementFr } from '../../agreements';

export const getCountryFlag = (lang: string, handleSelectOpen: () => void): React.ReactElement => {
  const flagSVGStyle = css`
    cursor: pointer;
  `;

  switch (lang) {
    case Languages.fr:
      return (
        <FlagFR
          onClick={handleSelectOpen}
          css={css`
            ${flagSVGStyle}
          `}
        />
      );
    case Languages.es:
      return (
        <FlagES
          onClick={handleSelectOpen}
          css={css`
            ${flagSVGStyle}
          `}
        />
      );
    case Languages.en:
    default:
      return (
        <FlagUS
          onClick={handleSelectOpen}
          css={css`
            ${flagSVGStyle}
          `}
        />
      );
  }
};

export const getCountryDialog = (lang: string, type?: 'agreement' | 'imageShare'): string => {
  switch (lang) {
    case Languages.fr:
      return type === 'agreement' ? agreementFr.text : imageShareFr.text;
    case Languages.es:
      return type === 'agreement' ? agreementEs.text : imageShareEs.text;
    case Languages.en:
    default:
      return type === 'agreement' ? agreementEn.text : imageShareEn.text;
  }
};
