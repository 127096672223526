/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef, useState } from 'react';
import { css } from '@emotion/react';
import { Box, Checkbox, FormControlLabel, Stack } from '@mui/material';
import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import EZButton from '../common/EZButton';
import EZSelect from '../common/EZSelect';
import EZTypography from '../common/EZTypography';
import { Languages, languageOptions } from '../common/const';
import { IPracticeInfo } from '../types';
import { getCountryDialog } from '../common/functions';
import QWebChannel from '../common/qt/qwebchannel';
import EziCloudApis from '../apis';
import EzTextField from '../common/EzTextField';

declare global {
  interface Window {
    qt: any;
    qtAppApi: any;

    agreementsPageApi: IAgreementApi;
  }
}

interface IAgreementApi {
  setLanguage(language: string): void;
  setDefaultPracticeInfo(info: IPracticeInfo): void;
}

export default function Agreements(): React.ReactElement {
  const [language, setLanguage] = useState<string>(Languages.en);
  const [defaultPracticeInfo, setDefaultPracticeInfo] = useState<IPracticeInfo>();
  const [practiceInfo, setPracticeInfo] = useState<IPracticeInfo>({
    practiceName: '',
    phoneNumber: '',
    email: '',
    address: '',
    consenter: '',
  });

  const [disableCheckbox, setDisableCheckbox] = useState(false);
  const [checkbox, setCheckbox] = useState({ first: false, second: false });

  const apis = useRef<IAgreementApi>();
  const ezicloudApis = useRef<EziCloudApis>(new EziCloudApis());

  const handleDialogSelect = (e: { target: { value: string } }) => {
    setLanguage(e.target.value);
  };
  const handleFirstCheckBoxClick = (e: { target: { checked: boolean } }) => {
    setCheckbox((prev) => ({ ...prev, first: e.target.checked }));
  };
  const handleSecondCheckBoxClick = (e: { target: { checked: boolean } }) => {
    setCheckbox((prev) => ({ ...prev, second: e.target.checked }));
  };

  const handleAcceptClick = async () => {
    console.group('HandleAccept');

    if (!window.qtAppApi) {
      console.log('qtAppApi is undefined.');
      return;
    }

    try {
      const token = await ezicloudApis.current.postClientCredentialsToken(
        process.env.REACT_APP_CLIENT_AGREEMENTS_SCOPE
      );

      const agreementInfo = {
        ...practiceInfo,
        service: 'linksharing',
        agree: checkbox.first && checkbox.second,
      };

      const res = await ezicloudApis.current.postAgreements(agreementInfo, token);
      if (res.errorCode.toUpperCase() === 'SUCCESS') {
        console.log('Success');
        if (window.qtAppApi.SetResult) {
          window.qtAppApi.SetResult({
            state: 'agree',
            service: 'linksharing',
            practiceInfo,
          });
        }
      } else {
        throw new Error(`Failed rest api: ${JSON.stringify(res)}`);
      }
    } catch (error) {
      console.log('Error: ', error);
      if (window.qtAppApi.SetResult) {
        window.qtAppApi.SetResult({
          state: 'fail',
          service: 'linksharing',
        });
      }
    } finally {
      console.log('Final HandleAccept');
      if (window.qtAppApi.CloseDialog) {
        console.log('---- CloseDialog');
        window.qtAppApi.CloseDialog();
      }
      console.groupEnd();
    }
  };

  const handleCancelClick = () => {
    if (!window.qtAppApi) {
      console.log('not supported');
      return;
    }

    if (window.qtAppApi.SetResult) {
      window.qtAppApi.SetResult({
        state: 'disagree',
        service: 'linksharing',
      });

      if (window.qtAppApi.CloseDialog) {
        window.qtAppApi.CloseDialog();
      }
    }
  };

  const handleChangePracticeInput = (id: string, value: string): void => {
    setPracticeInfo((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  const handleEmailValidation = (): boolean => {
    const regex = /[A-Za-z0-9]+@[A-Za-z0-9]+\.[A-Za-z0-9]{2,3}/;
    return !regex.test(practiceInfo.email);
  };

  useEffect(() => {
    console.log('APIServer: ', process.env.REACT_APP_ENV);

    if (window.qt) {
      // eslint-disable-next-line no-new
      new QWebChannel(window.qt.webChannelTransport, (channel: any) => {
        window.qtAppApi = channel.objects.qtAppApi;
      });

      if (apis.current === undefined) {
        apis.current = {
          setLanguage,
          setDefaultPracticeInfo,
        };
        window.agreementsPageApi = apis.current;
      }
    } else {
      setDefaultPracticeInfo({
        practiceName: '',
        phoneNumber: '',
        email: '',
        address: '',
        consenter: '',
      });
    }
  }, []);

  useEffect(() => {
    const disable =
      !practiceInfo.practiceName.trim() ||
      !practiceInfo.phoneNumber.trim() ||
      !practiceInfo.email.trim() ||
      !practiceInfo.address.trim() ||
      !practiceInfo.consenter.trim() ||
      handleEmailValidation();

    if (disable === true) setCheckbox({ first: !disable, second: !disable });
    setDisableCheckbox(disable);
  }, [practiceInfo]);

  return (
    <Stack
      css={css`
        width: 960px;
        height: 100vh;
        justify-content: center;
        margin: 0 auto;
        padding: 40px;
      `}
    >
      <Stack
        css={css`
          width: 100%;
          margin-bottom: 12px;
        `}
      >
        <Stack
          css={css`
            flex-direction: row;
            justify-content: space-between;
            align-items: baseline;
          `}
        >
          <EZTypography fontSize="20px">Legacy</EZTypography>
          <EZSelect
            options={languageOptions}
            value={language}
            variant="outlined"
            vertical={0}
            horizontal={240}
            minWidth="240px"
            onChange={handleDialogSelect}
          />
        </Stack>
        <Box
          css={css`
            height: 280px;
            border: 1px solid #dddddd;
            background-color: #f0f0f0;
            padding: 6px 14px;
            overflow: auto;
            &::-webkit-scrollbar-corner {
              background: rgba(0, 0, 0, 0);
            }
          `}
        >
          <EZTypography>{getCountryDialog(language, 'agreement')}</EZTypography>
        </Box>
      </Stack>
      {defaultPracticeInfo && (
        <>
          <EzTextField
            id="practiceName"
            label="Practice Name"
            defaultValue={defaultPracticeInfo.practiceName || ''}
            onChangeValue={handleChangePracticeInput}
          />
          <EzTextField
            id="phoneNumber"
            label="Phone Number"
            defaultValue={defaultPracticeInfo.phoneNumber || ''}
            onChangeValue={handleChangePracticeInput}
          />
          <EzTextField
            id="email"
            label="E-mail Address"
            defaultValue={defaultPracticeInfo.email || ''}
            onChangeValue={handleChangePracticeInput}
            error={handleEmailValidation() && !!practiceInfo.email?.length}
          />
          <EzTextField
            id="address"
            label="Address"
            defaultValue={defaultPracticeInfo.address || ''}
            onChangeValue={handleChangePracticeInput}
          />
          <EzTextField
            id="consenter"
            label="Consenter's Name"
            defaultValue={defaultPracticeInfo.consenter || ''}
            onChangeValue={handleChangePracticeInput}
          />
        </>
      )}
      <FormControlLabel
        control={<Checkbox checked={checkbox.first} onChange={handleFirstCheckBoxClick} />}
        label={i18n._(t`You confirm that you have received the consent from the patient of the collection and use of their personal data.
          `)}
        disabled={disableCheckbox}
        css={css`
          margin: 0;
          height: 32px;
          margin: 12px 0;
          .MuiTypography-root {
            font-size: 14px;
          }
          .MuiButtonBase-root {
            padding-left: 12px;
            padding-left: 16px;
          }
          .MuiSvgIcon-root {
            fill: #00796b;
          }
        `}
      />

      <FormControlLabel
        control={<Checkbox checked={checkbox.second} onChange={handleSecondCheckBoxClick} />}
        label={i18n._(t`You confirm that you have entered the accurate information and agreed with all contents above.
          `)}
        disabled={disableCheckbox}
        css={css`
          margin: 0;
          height: 32px;
          .MuiTypography-root {
            font-size: 14px;
          }
          .MuiButtonBase-root {
            padding-left: 12px;
            padding-left: 16px;
          }
          .MuiSvgIcon-root {
            fill: #00796b;
          }
        `}
      />
      <Stack
        css={css`
          width: 100%;
          margin-top: 12px;
          padding: 24px 0px;
          flex-direction: row;
          justify-content: center;
          align-items: center;
        `}
      >
        <EZButton
          bgColor="green"
          text={i18n._(t`Accept`)}
          onClick={handleAcceptClick}
          disabled={!checkbox.first || !checkbox.second}
        />
        <EZButton
          border="1px solid #144066"
          textColor="darkText"
          bgColor="white"
          text="Cancel"
          onClick={handleCancelClick}
        />
      </Stack>
    </Stack>
  );
}
