/* eslint-disable react/jsx-no-constructed-context-values */
import React, { useState, createContext, useContext, useCallback } from 'react';
import AuthData from './authData';

export interface IAuthState {
  valid: boolean;
}

interface IAuthContextProps {
  authState: IAuthState;
  updateState: () => Promise<void>;
}

const AuthContext = createContext<IAuthContextProps>({
  authState: {
    valid: false,
  },
  updateState: async (): Promise<void> => {
    // do nothing
  },
});

export default function AuthProvider(props: { children: React.ReactNode }): React.ReactElement {
  const { children } = props;

  const [authState, setAuthState] = useState<IAuthState>({ valid: false });

  const updateState = useCallback(async (): Promise<void> => {
    try {
      const session = await AuthData.getSessionByCurrentUser();
      setAuthState({
        valid: session.valid,
      });
    } catch (error) {
      setAuthState({
        valid: false,
      });
    }
  }, []);

  return <AuthContext.Provider value={{ authState, updateState }}>{children}</AuthContext.Provider>;
}

export function useValidSession() {
  const { authState } = useContext(AuthContext);
  return authState.valid;
}

export function useUpdateAuthState() {
  const { updateState } = useContext(AuthContext);
  return updateState;
}
