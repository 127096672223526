import { createTheme } from '@mui/material/styles';
import { buttonClasses } from '@mui/material/Button';

const theme = createTheme({
  palette: {
    primary: {
      main: '#00ccb4',
    },
    action: {
      disabledBackground: '#00796B',
      disabled: '#FFFFFF',
    },
  },
  components: {
    MuiButtonBase: {
      styleOverrides: {
        root: {
          [`&.${buttonClasses.disabled}`]: {
            opacity: 0.5,
          },
        },
      },
    },
  },
});

export default theme;
