/* eslint-disable no-console */
import React, { useState } from 'react';
import { css } from '@emotion/react';
import dayjs from 'dayjs';
import { t } from '@lingui/macro';
import { i18n } from '@lingui/core';
import { Checkbox, Stack, FormControlLabel, Box, TextField } from '@mui/material';
import EZTooltip from '../common/EZTooltip';
import EZTypography from '../common/EZTypography';
import EZButton from '../common/EZButton';
import EZDialog from '../common/EZDialog';
import { ICaseDetailInfoResponse } from '../types';
import { Loading } from '../common/const';

interface Props {
  data: ICaseDetailInfoResponse;
  count: number;
  maxCount: number;
  onClickDownloadButton: () => Promise<void>;
  checkDownloadCountExceeded: () => Promise<boolean>;
  setLoading: React.Dispatch<React.SetStateAction<Loading.image | Loading.download | ''>>;
}

const isValidDate = (date: Date): boolean => {
  // eslint-disable-next-line no-restricted-globals
  if (typeof date === 'object' && date !== null && !isNaN(date.getTime())) {
    return true;
  }
  return false;
};

export default function Header({
  data,
  count,
  maxCount,
  onClickDownloadButton,
  checkDownloadCountExceeded,
  setLoading,
}: Props): React.ReactElement {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [checked, setChecked] = useState(false);

  const handleDialogClose = () => {
    setChecked(false);
    setDialogOpen(false);
  };

  const handleDialogDownloadClick = async () => {
    handleDialogClose();
    setLoading(Loading.download);
    const downloadDisabled = await checkDownloadCountExceeded();
    if (!downloadDisabled) {
      await onClickDownloadButton();
    }
    setLoading('');
  };

  const handleCheckBoxClick = (e: { target: { checked: boolean } }) => {
    setChecked(e.target.checked);
  };

  const handleButtonClick = async () => {
    const downloadDisabled = await checkDownloadCountExceeded();
    if (!downloadDisabled) {
      setDialogOpen(true);
    }
  };

  const getPatientInfo = (): string => {
    if (data) {
      const patientInfoArray = [];
      const { chartNumber, name, gender, birthdate } = data.patientInfo;

      if (chartNumber) patientInfoArray.push(chartNumber);
      if (name) patientInfoArray.push(name);
      if (gender)
        patientInfoArray.push(
          gender === 'M' ? i18n._(t`Male`) : gender === 'F' ? i18n._(t`Female`) : '-'
        );

      if (birthdate) {
        const birthDateObj = new Date(birthdate);
        if (isValidDate(birthDateObj) === true) {
          let month = dayjs(new Date()).diff(birthdate, 'month');
          const year = Math.floor(month / 12);
          month -= year * 12;
          patientInfoArray.push(`${year}Y ${month}M`);
        } else {
          patientInfoArray.push(birthdate);
        }
      }
      return patientInfoArray.join(' / ');
    }
    return 'No Information';
  };

  return (
    <Stack>
      {data && (
        <Stack
          css={css`
            height: 132px;
            flex-direction: row;
            align-items: flex-end;
            padding: 16px 32px;
            text-align: center;
          `}
        >
          <Stack
            css={css`
              max-width: 340px;
              height: 97px;
              align-items: center;
            `}
          >
            <div
              css={css`
                position: relative;
                width: 50px;
                height: 50px;
                margin-bottom: 12px;
              `}
            >
              <Box
                css={css`
                  position: absolute;
                  display: flex;
                  width: 50px;
                  height: 50px;
                  border-radius: 50%;
                  background: linear-gradient(180deg, #fad8c3 0%, #cc9d83 100%);
                  justify-content: center;
                  align-items: center;
                  margin-bottom: 12px;
                `}
              >
                <EZTypography fontSize="28px" color="white">
                  {data.ownerInfo.name && data.ownerInfo.name[0].toUpperCase()}
                </EZTypography>
              </Box>
            </div>
            {!data?.ownerInfo.name && !data?.ownerInfo.phoneNumber ? (
              <EZTypography>No information</EZTypography>
            ) : (
              <>
                {data.ownerInfo.name && (
                  <EZTooltip title={data.ownerInfo.name}>
                    <span
                      css={css`
                        width: 100%;
                        color: #222222;
                        font-size: 14px;
                        font-weight: 600;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        line-height: 1.5;
                      `}
                    >
                      {data.ownerInfo.name}
                    </span>
                  </EZTooltip>
                )}
                {data.ownerInfo.phoneNumber && (
                  <EZTooltip title={data.ownerInfo.phoneNumber}>
                    <span
                      css={css`
                        color: #999999;
                        font-size: 12px;
                        width: 100%;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        line-height: 1.5;
                      `}
                    >
                      {data.ownerInfo.phoneNumber}
                    </span>
                  </EZTooltip>
                )}
              </>
            )}
          </Stack>
          <Stack
            css={css`
              flex: 1;
              align-items: start;
              margin: 0 16px;
            `}
          >
            <EZTypography
              fontSize="12px"
              css={css`
                line-height: 1;
              `}
            >
              {dayjs(data.createdTime).format('YYYY-MM-DD HH:mm:ss')}
            </EZTypography>
            <TextField
              disabled
              value={data.comment}
              variant="standard"
              InputProps={{ disableUnderline: true, rows: 2.3 }}
              multiline
              css={css`
                width: 100%;
                min-width: 600px;
                border: 1px solid #dddddd;
                border-radius: 0px 10px 10px 24px;
                margin-top: 8px;
                background: #f0f0f0;
                .MuiInputBase-input.Mui-disabled {
                  margin: 8px 0;
                  padding: 0 20px;
                  text-fill-color: #222222;
                }
              `}
            />
          </Stack>
          {count !== undefined && maxCount !== undefined && (
            <EZButton
              disabled={count > maxCount}
              width="112px"
              height="80px"
              bgColor="orange"
              onClick={handleButtonClick}
              text="Download"
            />
          )}
          <EZDialog
            margin="0 24px 16px 24px"
            open={dialogOpen}
            title="Message"
            onClose={handleDialogClose}
          >
            <Stack
              css={css`
                width: 688px;
                height: 104px;
                text-align: center;
                border-radius: 5px;
                background: #f4f4f4;
                padding: 18px 0 17px 0;
                justify-content: space-between;
              `}
            >
              <FormControlLabel
                control={<Checkbox checked={checked} onChange={handleCheckBoxClick} />}
                label={i18n._(
                  t`Once you check here, you agree to use this data observing the relevant law and regulation.`
                )}
                css={css`
                  margin: 0 auto;
                  height: 19px;
                  .MuiTypography-root {
                    font-size: 14px;
                  }
                `}
              />
              <EZTypography color="red" fontSize="12px" fontWeight="400">
                {i18n._(t`Unlawful access or use of data may be subject to legal responsibility.`)}
              </EZTypography>
              {count && <EZTypography>{`[Download ${count}/${maxCount}]`}</EZTypography>}
            </Stack>
            <Stack
              css={css`
                flex-direction: row;
                justify-content: center;
                margin-top: 16px;
              `}
            >
              <EZButton
                bgColor="green"
                onClick={handleDialogDownloadClick}
                text="Download"
                disabled={!checked}
              />
              <EZButton
                border="1px solid #144066"
                textColor="darkText"
                bgColor="white"
                onClick={handleDialogClose}
                text="Cancel"
              />
            </Stack>
          </EZDialog>
        </Stack>
      )}
      <Box
        css={css`
          display: flex;
          height: 45px;
          align-items: center;
          padding: 0 24px;
          background: #242424;
        `}
      >
        <EZTypography color="white">{getPatientInfo()}</EZTypography>
      </Box>
    </Stack>
  );
}
