export interface IResponse {
  errorCode: string;
  errorMessage: string;
}

export enum ErrorStage {
  Failed = 0,
  FailedCaseState,
}

export interface IBriefInfoResponse extends IResponse {
  uuid: string;
  title: string;
  status: 'wait' | 'shared' | 'accessed' | 'expired' | 'failed';
  accessCount: string;
  downloadCount: string;
}

export type CaseStateType =
  | 'accessed'
  | 'shared'
  | 'expired'
  | 'wait'
  | 'failed'
  | 'downloadQuotaExceeded';

interface ILoginBoxState {
  title: string;
  state: CaseStateType;
}

export interface IPreviewerInfoResponse extends IResponse {
  case: ILoginBoxState & {
    cognitoid: string | undefined;
  };
  policy: {
    passwordPolicy: IPasswordPolicyResponse;
    downloadPolicy: IDownloadPolicyResponse;
  };
}

interface IPatientInfo {
  chartNumber?: string;
  name?: string;
  gender?: string;
  birthdate?: string;
}

interface IOwnerInfo {
  name?: string;
  phoneNumber?: string;
}

export interface ICaseDetailInfoResponse extends IBriefInfoResponse {
  comment: string;
  patientInfo: IPatientInfo;
  ownerInfo: IOwnerInfo;
  caseFileKey: string;
  previewFileKeys: string[];
  anonymized: boolean;
  createdTime: Date;
}

export interface ISignedUrlResponse extends IResponse {
  url: string;
  fileKey: string;
}

export type Policy = 'uploadPolicy' | 'downloadPolicy' | 'passwordPolicy' | 'supportedRegion';

export interface IDownloadPolicyResponse {
  maximumCount: number;
}

export interface IPasswordPolicyResponse {
  maximumLength: number;
  minimumLength: number;
}

interface IUploadPolicyResponse {
  maximumFileCount: number;
  maximumFileSize: number;
  maximumUploadCountPerDay: number;
}
export interface IPolicyResponse extends IResponse {
  downloadPolicy: IDownloadPolicyResponse;
  passwordPolicy: IPasswordPolicyResponse;
  supportedRegion: string[];
  uploadPolicy: IUploadPolicyResponse;
}

export interface IPracticeInfo {
  practiceName: string;
  phoneNumber: string;
  email: string;
  address: string;
  consenter: string;
}
