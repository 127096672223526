import * as React from 'react';
import Tooltip, { TooltipProps } from '@mui/material/Tooltip';

interface Props extends TooltipProps {
  title: string;
  children: React.ReactElement;
}

export default function EZTooltip({ title, children }: Props): React.ReactElement {
  return <Tooltip title={title}>{children}</Tooltip>;
}
