import React from 'react';
import Button, { ButtonProps } from '@mui/material/Button';
import { css } from '@emotion/react';

interface Props extends ButtonProps {
  width: string;
  height: string;
  border?: string;
  textColor: 'darkText' | 'white';
  bgColor: 'primary' | 'green' | 'orange' | 'white';
  text: string;
}

const colors = {
  primary: '#2B918B',
  green: '#00796B',
  orange: 'linear-gradient(180deg, #FFC970 0%, #FFA91F 100%)',
  white: '#FFFFFF',
  darkText: '#144066',
};

const ButtonStyle = css`
  border-radius: 5px;
  text-transform: none;
  align-self: start;
  :hover {
    font-weight: bold;
  }
`;

export default function EZButton({
  width,
  height,
  border,
  textColor,
  bgColor,
  text,
  disabled,
  ...rest
}: Props): React.ReactElement {
  return (
    <div
      css={css`
        & + & {
          margin-left: 8px;
        }
      `}
    >
      <Button
        variant="contained"
        type="button"
        disabled={disabled}
        css={css`
          ${ButtonStyle}
          width:${width};
          height: ${height};
          border: ${border};
          color: ${colors[textColor]};
          background: ${colors[bgColor]};
          :hover {
            border: ${border};
            background: ${colors[bgColor]};
          }
        `}
        {...rest}
      >
        {text}
      </Button>
    </div>
  );
}

EZButton.defaultProps = {
  width: '94px',
  height: '36px',
  border: 'none',
  textColor: 'white',
  bgColor: 'primary',
  text: 'Download',
};
