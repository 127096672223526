export enum Languages {
  en = 'English',
  fr = 'French',
  es = 'Spanish',
}

export enum Loading {
  previewInfo = 'previewInfoLoading',
  login = 'loginLoading',
  image = 'imageLoading',
  download = 'downloadLoading',
}

export enum ErrorCodes {
  Expired = 'Expired',
}

export const languageOptions = [Languages.en, Languages.fr, Languages.es];
